import React from 'react';
import backgroundImg from '../../../components/assets/img/pages/landing/blue-background.jpg';
// import { thirdSectionWrapper } from "../data";
import Icon1 from '../../../components/assets/img/pages/landing/icon-1.png';
import Icon2 from '../../../components/assets/img/pages/landing/icon-2.png';
import Icon3 from '../../../components/assets/img/pages/landing/icon-3.png';
import Icon4 from '../../../components/assets/img/pages/landing/icon-4.png';
import Icon5 from '../../../components/assets/img/pages/landing/icon-5.png';
import Icon6 from '../../../components/assets/img/pages/landing/icon-6.png';

const ThirdSection = () => {
  const thirdSectionWrapper = [
    {
      IMG: Icon1,
      Title: 'Presente certo',
      Text: ['Encontre o presente certo de acordo com as preferências de quem você quer agradar. Com Gifthy você acerta sempre!'], 
    },
    {
      IMG: Icon2,
      Title: 'Aproveite o seu tempo',
      Text: ['Com apenas alguns cliques você consegue finalizar sua compra. É seguro, fácil e rápido! Evite aquelas intermináveis filas de pagamento. O Gifthy pensa no seu tempo e sabe o quanto ele é valioso para você.'], 
    },
    {
      IMG: Icon3,
      Title: 'Em qualquer lugar',
      Text: ['Com Gifthy você faz sua compra de onde você estiver, em casa ou viajando em férias ou a trabalho. Sem preocupação e incômodos.', 'E o presente será entregue onde você desejar. Na esquina da sua casa ou do outro lado do mundo.'], 
    },
    {
      IMG: Icon4,
      Title: 'Sem incômodos',
      Text: ['O Gifthy garante conforto e tranquilidade.', 'Você não quer enfrentar lojas lotadas para comprar um presente.', 'E os congestionamentos? Para acertar o presente sem ferver a cabeça, basta contar com o auxílio da inteligência digital do Gifthy.'], 
    },
    {
      IMG: Icon5,
      Title: 'Garantia de entrega',
      Text: ['Não se preocupe. O presente vai chegar a tempo!', 'O Gifthy conta com os melhores fornecedores para garantir que o seu presenteado receba o presente rapidamente e em perfeito estado. Você também pode receber no seu endereço, para levar pessoalmente na festa. Plataformas de logística modernas e ágeis contribuem para a melhor experiência possível.'], 
    },
    {
      IMG: Icon6,
      Title: 'O melhor preço',
      Text: ['Com o Gifthy você não tem custos extras com tarifas de transporte, combustível ou estacionamento. Você paga exatamente o valor do presente desejado.'], 
    },
  ];
  return (
    <section className="third padding-7" id="por-que" style={{background: `url(${backgroundImg}) no-repeat center/cover`}}>
      <h1 className="title yellow-font bold text-center">Por que usar o Gifthy?</h1>
      <h2 className="subtitle white-font bold text-center">O Gifthy surpreende você e quem você deseja presentear.</h2>
      <p className="description white-font text-center container-50">Além de notificar datas importantes, também apresenta um catálogo completo com sugestões de produtos, conforme o perfil do presenteado, para não ter erro na hora da escolha. você poupa tempo e evita incômodos, como trânsito e shoppings abarrotados.</p>
      <div className="wrapper">
        {thirdSectionWrapper.map((item, index) => (
          <div key={index.toString()} className="item">
            <div className="img">
              <img src={item.IMG} />
            </div>
            <h2 className="title white-font text-center">{item.Title}</h2>
            {item.Text.map((text, index) => (
              <p key={index.toString()} className="paragraph white-font text-justify">{text}</p>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
};

export default ThirdSection;